import React  from "react";
import {Text,Heading,Box, SimpleGrid} from '@chakra-ui/react'
import { StaticImage } from "gatsby-plugin-image";
import Link from '../components/gatsbylink'
import MeinSeo from '../components/seo'
import {motion} from 'framer-motion'
export default function Lichtenstein(){
    return(
        <motion.div 
        initial={{opacity:0,paddingBottom:"100px"}}
        animate={{
          opacity:1
        }}
        transition={{duration:.1,delay:.1,ease:"circInOut"}}
        >  
        <MeinSeo title="Das Schicksal der jüdischen Familie Lichtenstein aus Oberwesel" description="Bilder und Dokumente zum Schicksal der Familie Lichtenstein aus Oberwesel" />

        <Box  mt="60px"><Link to="/oberwesel"><Text> &rarr; back</Text></Link></Box>
        <SimpleGrid py="8" gap={4} columns={[1,2,2]}>
        <Box>
            <Heading variant="utitel">Schaarplatz 1
            <br/>
            Karl und Regina Lichtenstein, Willy und Clementine Lichtenstein mit Herbert und Meta, 
            Theodor und Selma Lichtenstein mit Karl-Heinz, Günther und Ruth

            </Heading>
            
            <Text variant="solid">
            Auf dem Schaarplatz 1 wohnten die Nachfahren des aus Laurenzberg bei Eschweiler zugewanderten Jakob Lichtenstein (*1827). Zuletzt lebte er bei seiner Tochter Sara (verheiratet mit Max Meyer) in St. Goar. In Oberwesel wurde er 1904 bestattet. In dem Haus unmittelbar neben der Synagoge wohnte die Familie seines Sohnes Karl Lichtenstein (*1857 in Glesch an der Erft). Der Viehhändler, Metzger und Winzer heiratete Regina Mayer (*1853–1923). Ihre sechs Kinder sind Amalie (*1885), Willy (*1886), Frieda (*1887), Theo (*1888), Heinrich (*1889) und Moritz (*1891). Willy und Theo Lichtenstein gründeten in Oberwesel Familien und lebten zusammen in dem Haus am Schaarplatz. Moritz ist 1914 gefallen, Willy wurde schwer verwundet, Theo kam als Offizier zurück. 1937 verunglückte Willy tödlich auf der Simmerner Landstraße bei der Abzweigung nach Damscheid. Amalie, verheiratet mit Jacob Levis in Eppelsheim, konnte mit ihrer Familie in die USA fliehen. Ebenso der Lehrer Heinrich, verheiratet mit Ina Stein aus Grebenau. Frieda, verheiratet mit Leo Mange in Hadamar, floh mit der Familie nach Argentinien. Karl Lichtenstein, seine Schwiegertochter Selma (*1890 in Willmenrod), seine Enkelkinder Karl-Heinz (*1922), Günther (*1925) und Ruth (*1931) sowie sein Enkel Herbert (*1920) haben das KZ überlebt. Sie kamen am 25.07.1945 ausgehungert und krank nach Oberwesel zurück. Karls Sohn Theo und seine Enkelin Meta (*1921) wurden ermordet. Ende 1946 verließen sie ihre Heimat und fuhren mit der »Marine Marlin« nach New York.
            </Text>
            <Heading variant="utitel">8. November 1938</Heading>
            <Text variant="solid">In der Pogromnacht werden bei Lichtensteins die Fensterscheiben eingeworfen.</Text>
            <Heading variant="utitel">10. November 1938</Heading>
            <Text variant="solid">Nach der Pogromnacht wird Karls Sohn Heinrich Lichtenstein, Lehrer in Offenbach, in das KZ Buchenwald verschleppt und nach drei Wochen entlassen.
            </Text>
            <Heading variant="utitel">15. November 1938</Heading>
            <Text variant="solid">Ruth Lichtenstein wird von SA-Männern aus der Schulklasse geholt, sie darf die Schule nicht weiter besuchen.</Text>
            <Heading variant="utitel">13. Februar 1939 </Heading>
            <Text variant="solid">
            Herbert Lichtenstein, der Sohn von Willy und Clementine, wird 1939/40 zur Arbeit im Staatsforst bei Fürstenwalde gezwungen. 1940 bringt ihn die Gestapo in ein Arbeitslager in Bielefeld, von wo er 1943 nach Auschwitz deportiert wird. Im Januar 1945 werden die Häftlinge ins KZ Buchenwald verlegt. Im Teillager Ohrdruf-Espenfeld erleidet Herbert beim Bau der unterirdischen Fabrik einen Unfall, er hinkt fortan. Im April 1945 wird er im offenen Waggon nach Theresienstadt transportiert. Er erkrankt an Typhus.
            </Text>
            <Heading variant="utitel">19. Juli 1939</Heading>
            <Text variant="solid">Der Lehrer Heinrich Lichtenstein kann mit seiner Frau Ina, geb. Stein, mit einem Flugzeug von Köln nach Amsterdam fliehen. In London schreibt er 1940 seine Erinnerungen auf. Von Glasgow aus reisen sie am 01.09.1949 nach New York, dort aber findet er keine dauerhafte Stellung. Heinrich Lichtenstein nimmt sich am 23.03.1961 das Leben.</Text>
            <Heading variant="utitel">28. Februar 1941</Heading>
            <Text variant="solid">Willys Tochter Meta Lichtenstein wird von Frankfurt, wo sie als Hausgehilfin in Stellung war, nach Berlin verbracht und dem »geschlossenen Arbeitseinsatz« bei den Siemens-Schuckert-Werken zugewiesen. Sie wird am 03.03.1943 vom Güterbahnhof Moabit aus nach Auschwitz deportiert und ermordet.</Text>
            <Heading variant="utitel"> 2. April 1942</Heading>
            <Text variant="solid">
            Theo und Selma Lichtenstein und die Kinder Karl-Heinz, Günther und Ruth werden ins Sammellager Bad Salzig deportiert. Sie werden am 29.04. zurück nach Oberwesel gebracht, da Theo 1918 als Offizier gedient hat. Ab jetzt müssen sie in dem »Judenhaus« von Adolf Seligmann auf dem Heumarkt wohnen, wo schon Karl Lichtenstein lebt.
            </Text>
            <Heading>27. Juli 1942</Heading>
            <Text variant="solid">Lichtensteins werden zusammen mit den anderen verbliebenen Juden zum Bahnhof getrieben und zum Güterbahnhof Koblenz-Lützel gebracht. Dort werden sie in den Zug nach Theresienstadt verladen. Theo Lichtenstein wird nach Auschwitz verbracht und dort ermordet. Dorthin werden auch die Brüder Karl-Heinz und Günther verschleppt.</Text>
            
            </Box>
           
                <Box pt="10">
                 <SimpleGrid columns={[1,1,1,2]} gap="2"> 
                <Box p="1">
                    <StaticImage src="../images/solo/lichtenstein/Karl-Lichtenstein.jpg" />                    <Text variant="Bild"> Karl-Lichtenstein</Text>
                </Box>
                
                <Box p="1">
                    <StaticImage src="../images/solo/lichtenstein/Jakob-und-Helene-Lichtenstein.jpg" /> 
                    <Text variant="Bild"> Jakob und Helene Lichtenstein</Text>
                </Box>
                
                
               
                <Box p="1">
                    <StaticImage src="../images/solo/lichtenstein/Theo-Lichtenstein.jpg" /> 
                    <Text variant="Bild"> Theo Lichtenstein</Text>
                </Box>
                <Box p="1">
                    <StaticImage src="../images/solo/lichtenstein/Heinrich-und-Ina-Lichtenstein-mit-Karl-Heinz-und-Irene-Lichtenstein- geb-Wolff-und-ihren-Kindern.jpg" /> 
                    <Text variant="Bild"> Heinrich, Ina, Karl-Heinz und Irene Lichtenstein, geb.Wolff und Kinder</Text>
                </Box>
                
                
                
                <Box p="1">
                    <StaticImage src="../images/solo/lichtenstein/Selma-Lichtenstein-Liste-Hausrat.jpg" /> 
                    <Text variant="Bild"> Meta Lichtensteins Hausratsliste</Text>
                </Box>
                <Box p="1">
                    <StaticImage src="../images/solo/lichtenstein/Betsaal-Koblenz-1946-Karl-Lichtenstein.jpg" /> 
                    <Text variant="Bild"> Betsaal Koblenz Karl Lichtenstein(1946)</Text>
                </Box>
                <Box p="1">
                    <StaticImage src="../images/solo/lichtenstein/Ruth-Lichtenstein.jpg" /> 
                    <Text variant="Bild">Ruth Lichtenstein</Text>
                </Box>
                <Box p="1">
                    <StaticImage src="../images/solo/lichtenstein/Herbert-Lichtenstein.jpg" /> 
                    <Text variant="Bild">Herbert Lichtenstein</Text>
                </Box>
                </SimpleGrid>
                <Box p="1">
                    <StaticImage src="../images/solo/lichtenstein/Lichtenstein-Deportationsliste.jpg" /> 
                    <Text variant="Bild"> Deportationsliste-Lichtenstein</Text>
                </Box>
                <Box p="1">
                    <StaticImage src="../images/solo/lichtenstein/Meta-Lichtenstein-Karteikarte.jpg" /> 
                    <Text variant="Bild"> Meta Lichtensteins Karteikarte</Text>
                </Box>
                <Box p="1">
                    <StaticImage src="../images/solo/lichtenstein/Haus-Lichtenstein-neben-Synagoge-1920.jpg" /> 
                    <Text variant="Bild"> Haus Lichtenstein neben der Synagoge (1920)</Text>
                </Box>
                
            </Box>
            </SimpleGrid>
            </motion.div>
    )
}